import { Link } from "react-router-dom";
import powered from "../assets/powered-by-workplay-white.png";
const { REACT_APP_LOGO_URL, REACT_APP_CONTACT_URL } = process.env;

interface Props {
  seconds: number;
}

function Result({ seconds }: Props) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const TimeOut = seconds >= 120;

  const handleContact = () => {
    window.open(`${REACT_APP_CONTACT_URL}`, "_blank");
  };

  return (
    <section>
      <div className="flex flex-col justify-center items-center my-44">
        {TimeOut ? (
          <h1 className="title my-0 text-2xl font-semibold md:text-4xl md:my-4">
            Time out!!
          </h1>
        ) : (
          <h1 className="title my-0 text-2xl font-semibold md:text-4xl md:my-4">
            Great Job!
          </h1>
        )}
        {TimeOut ? (
          <p className=" description text-xl my-5 font-semibold w-80 md:text-2xl md:w-2/4 md:my-10">
            Sorry, you couldn't solve the puzzle in time.
          </p>
        ) : (
          <p className=" description my-10 text-xl font-semibold w-80 md:text-2xl md:w-5/6">
            You've completed the Challenge in {minutes}m {remainingSeconds}s
          </p>
        )}
        <Link to="/">
          <button className="btn">TRY AGAIN</button>
        </Link>

        <button
          className="btn"
          style={{ marginTop: "20px" }}
          onClick={handleContact}
        >
          CONTACT US
        </button>

        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          {" "}
          <img
            id="powered"
            src={powered}
            className="my-8 h-16"
            alt="poweredBy-workplay"
          />
        </a>
      </div>
    </section>
  );
}

export default Result;
