"use client";
// @ts-ignore

import { Canvas, painters, outline, generators } from "headbreaker";
import "../Styles/Puzzle.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, Dispatch, SetStateAction } from "react";
import image from "../assets/workplay-puzzle.png";
import puzzleCanvas from "../assets/puzzle-canvas.png";
import timer from "../assets/timer-grey.png";
import logo from "../assets/workplay-logo-with-shadow.png";
import { useMediaQuery } from "react-responsive";
const { REACT_APP_LOGO_URL } = process.env;

interface Props {
  seconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
}

function Puzzle({ seconds, setSeconds }: Props) {
  const puzzleRef = useRef(null);
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery({ maxWidth: 500 });
  const isMediumScreen = useMediaQuery({ maxWidth: 830 });

  let width: number, height: number, pieceSize: number;

  if (isSmallScreen) {
    width = 320;
    height = 350;
    pieceSize = 60;
  } else if (isMediumScreen) {
    width = 500;
    height = 400;
    pieceSize = 75;
  } else {
    // Default values for larger screens
    width = 1000;
    height = 600;
    pieceSize = 90;
  }

  let puzzle_image = new Image();
  puzzle_image.src = image;

  useEffect(() => {
    // Listen to the 'load' event to ensure the image is fully loaded.
    puzzle_image.onload = () => {
      // @ts-ignore
      const canvas = new Canvas(puzzle.id, {
        image: puzzle_image,
        painter: new painters.Konva(),
        width: width,
        height: height,
        pieceSize: pieceSize,
        strokeWidth: 0,
        outline: new outline.Rounded(),
        preventOffstageDrag: true,
        fixed: true,
      });

      canvas.adjustImagesToPuzzleWidth();
      canvas.autogenerate({
        horizontalPiecesCount: 4,
        verticalPiecesCount: 4,
        insertsGenerator: generators.random,
      });

      canvas.onConnect(
        (_piece: any, figure: any, _target: any, targetFigure: any) => {
          figure.shape.strokeWidth(0);
          targetFigure.shape.strokeWidth(0);
        }
      );

      canvas.onDisconnect(
        (_piece: any, figure: any, _target: any, targetFigure: any) => {
          figure.shape.strokeWidth(1.8);
          targetFigure.shape.strokeWidth(1.8);
        }
      );

      canvas.shuffle(0.9);
      canvas.draw();
      canvas.attachSolvedValidator();
      canvas.onValid(() => {
        setTimeout(() => {
          navigate("/result");
        }, 1000);
      });
    };
    // Reset the timer to zero when the component mounts
    setSeconds(0);

    // Start the timer when the component mounts
    const timer = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000); // Timer increments every 1 second (1000 milliseconds)

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (seconds > 119) {
    navigate("/result");
  }

  return (
    <>
      <div className=" flex flex-col items-center justify-center my-8  ">
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noreferrer">
          <img src={logo} className="h-9 my-1 md:h-10" alt="workplay-logo" />
        </a>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center my-4 md:my-6">
              <img src={timer} className="h-10 " alt="timer" />
              <h3 className="text-center">
                0{minutes} : {remainingSeconds < 10 ? "0" : ""}
                {remainingSeconds}
              </h3>
            </div>
            <div className="">
              {" "}
              <img
                src={puzzleCanvas}
                className="h-36 m sm:h-40 md:h-56"
                alt="workplay studios"
              />
            </div>
          </div>
          <div
            ref={puzzleRef}
            id="puzzle"
            className=" puzzle ml-0 md:ml-20"
          ></div>
        </div>
      </div>
    </>
  );
}

export default Puzzle;
