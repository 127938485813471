import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Puzzle from "./Components/Puzzle";
import Home from "./Components/Home";
import Result from "./Components/Result";
import { useState } from "react";

function App() {

  const [seconds, setSeconds] = useState<number>(0);;

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="result" element={<Result seconds={seconds} />}></Route>
          <Route
            path="game"
            element={<Puzzle seconds={seconds} setSeconds={setSeconds}  />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
