import { Link } from "react-router-dom";
import logo from "../assets/workplay-logo-with-shadow.png";
const { REACT_APP_LOGO_URL } = process.env;

function Home() {
  return (
    <section className="">
      <div className="flex flex-col justify-center items-center my-24 ">
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={logo}
            alt="workplay-digital"
            className="my-6 h-9 md:h-10"
            id="workplay logo"
          />
        </a>
        <h1 className="title text-2xl font-semibold md:text-3xl">
          Jigsaw Challenge
        </h1>
        <p className=" description my-8 text-xl font-semibold w-80 md:text-2xl md:w-2/4">
          Welcome to the Jigsaw Challenge! Move the pieces in the quickest time
          possible to form and reveal the image. Best of luck!!
        </p>
        <Link to="/game">
          <button className="btn">START</button>
        </Link>
      </div>
    </section>
  );
}

export default Home;
